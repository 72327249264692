.product-detail-header {
	position: relative;
	padding-top: 5.5rem;
	color: $theme-color-primary;

	h1 {
		font-size: 3.375rem;
	}
}

.product-detail {
	display: flex;
	flex-wrap: wrap;
	padding-bottom: 5rem;
}

@keyframes bounce {

	0%,
	20%,
	50%,
	80%,
	100% {
		transform: translate(-50%, -50%);
	}

	40% {
		transform: translate(-50%, -70%);
	}

	60% {
		transform: translate(-50%, -60%);
	}
}

.product-detail-header-arrow {
	position: absolute;
	top: 0;
	left: 50%;
	transform: translate(-50%, -50%);
	z-index: 2;
	animation: bounce 2s infinite;
	animation-delay: 2s;
}

.product-detail-info {
	display: flex;
	flex-wrap: nowrap;
	justify-content: center;
	border-top: 6px solid $theme-color-secondary;
	border-bottom: 6px solid $theme-color-secondary;
	padding: 3.125rem 0;
	margin-top: 4rem;
	margin-bottom: 4rem;
	gap: 1rem;

	@media all and (max-width: $breakpoint-mobile) {
		flex-flow: wrap column;
		align-items: center;
		justify-content: center;
	}
}

.product-detail-info-item {
	flex: 1;
	flex-shrink: 0;
	padding-left: 4rem;
	font-size: 1.0625rem;
	font-weight: 700;
	color: $theme-color-secondary;
	max-width: 285px;
	width: 100%;

	a {
		color: $theme-color-secondary;
	}

	@media all and (max-width: 768px) {
		font-size: .875rem;
	}

	&.icon-place {
		background: url("../../images/icon-place.svg") no-repeat left top;
	}

	&.icon-contact {
		background: url("../../images/icon-contact.svg") no-repeat left top;
	}

	&.icon-website {
		background: url("../../images/icon-website.svg") no-repeat left top;
	}
}

.product-detail-info-heading {
	font-size: 1.375rem;
	font-weight: 700;
	margin-bottom: 0;
	color: $theme-color-primary;
	line-height: 1.09;

	@media all and (max-width: 768px) {
		font-size: 1.125rem;
	}
}

.product-detail-perex {
	font-size: 1.0625rem;
	color: $theme-color-secondary;
	text-align: center;
	font-weight: 700;
	max-width: 650px;
	margin: 1.25px auto 0;
}

.product-detail-content {
	flex: 1;
	padding-right: 5rem;
	font-size: 1.0625rem;
	line-height: 1.47;

	@media all and (max-width: 1024px) {
		padding-right: 0;
	}
}

.product-detail-sidebar {
	flex: 0 0 300px;

	img {
		max-width: 100%;
		height: auto;
	}

	@media all and (max-width: 1024px) {
		flex: 0 0 100%;
		order: -1;
		max-width: 100%;
		margin-bottom: 3rem;
	}
}

.product-detail-sidebar-wrapper {
	background-color: $theme-color-blue-light;
	padding: 2rem 2.5rem;

	& + .product-detail-sidebar-wrapper {
		margin-top: 1.875rem;
	}
}

.product-detail-gallery {
	position: relative;
	margin-bottom: 5.5rem;
	overflow: hidden;
}

.product-detail-gallery-item {
	width: 100%;
	max-width: 1000px;
	height: auto;

	@media all and (max-width: 1920px) {
		max-width: 800px;
	}

	img {
		width: 100%;
		height: auto;
	}
}

.product-detail-sidebar-logo {
	text-align: center;
	margin-bottom: 1.75rem;
}

.product-detail-discount {
	font-size: 1.25rem;
	font-weight: 700;
	text-align: center;
}

.product-detail-sidebar-heading {
	font-size: 1.375rem;
	font-weight: 700;
	text-align: center;
	line-height: 118.182%;
	color: $theme-color-primary;
	margin-bottom: 1.25rem;
}

.product-detail-reservation-text {
	font-size: 1.0625rem;
	line-height: 1.47;
	margin-bottom: 1.75rem;
}

.product-detail-button-reservation {
	display: flex;
	width: 100%;
	padding: 1rem 1.5rem;
	justify-content: center;
	align-items: center;
	gap: 10px;
	align-self: stretch;
	background: $theme-color-secondary;
	color: #fff;
	text-decoration: none;
	font-weight: 700;
	line-height: 1.5;

	&:hover {
		color: #fff;
	}
}
