.section-map {
	position: relative;
}

#results-map {
	height: 280px;
	width: 100%;
	transition: height .3s ease-in-out;

	&.open {
		height: 960px;

		.map-custom-control {
			opacity: 1;
		}

		@media all and (max-width: 1024px) {
			height: 65vh;
		}
	}
}

.leaflet-container a.leaflet-popup-close-button {
	font-family: $fontHeading;
	color: $theme-color-secondary;
	font-weight: 700;
	font-size: 1rem;
}

.leaflet-popup-content-wrapper {
	border-radius: 0;
	font-family: $fontHeading;
	box-shadow: none;

	.leaflet-popup-content {
		padding: 30px 40px;
		margin: 0;
	}

	.marker-title {
		color: $theme-color-primary;
		font-size: 1.0625rem;
		font-weight: 700;
		margin-bottom: 20px;
	}

	p {
		font-size: 1rem;
		color: $theme-color-secondary;
		margin-bottom: 20px;
	}

	a {
		color: $theme-color-primary;
		font-weight: 700;
		font-size: 1rem;
		text-decoration: none;
	}
}

.mycluster {
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: rgba(251, 82, 113, 70%);
	color: #fff;
	width: 70px;
	height: 70px;
	font-size: 1.5rem;
	font-weight: 700;
	font-family: $fontHeading;
	border-radius: 50%;
}

.map-custom-control {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	opacity: 0;
	transition: opacity .3s ease-in-out;

	.zoom-in,
	.zoom-out,
	.get-location {
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: #fff;
		width: 75px;
		height: 75px;
		padding: 0;
		margin: 0;
		border: 0;
		outline: 0;
	}

	.zoom-in {
		border-bottom: 1px solid $theme-color-secondary;
	}

	.get-location {
		margin-top: 24px;
	}
}

#map-toggle-button {
	display: flex;
	align-items: center;
	justify-content: center;
	background: $theme-color-secondary;
	width: 168px;
	height: 60px;
	border: 0;
	outline: 0;
	position: absolute;
	bottom: 0;
	left: 50%;
	transform: translateX(-50%);
	z-index: 1500;

	&.open svg {
		transform: rotate(180deg);
	}
}

.section-category {
	padding: 4rem 0;
}

.results {
	background-color: $theme-color-blue-light;
	padding-top: 3rem;
	padding-bottom: 5rem;
}

.product-card {
	display: flex;
	flex-direction: column;
	background-color: white;
	overflow: hidden;
	position: relative;
	width: 100%;
	height: 100%;
}

.product-image {
	width: 100%;
	overflow: hidden;

	img {
		width: 100%;
		height: auto;
	}
}

.product-card-body {
	display: flex;
	flex-direction: column;
	padding: 1.875rem 2.5rem 0;
	height: auto;
	flex: 1;
}

.product-card-footer {
	width: 100%;
	padding: 0 2.5rem 1.875rem;
}

.product-name {
	font-size: 1rem;
	font-weight: 700;
	padding-bottom: 1rem;
	color: $theme-color-primary;
}

.product-card-perex {
	overflow: hidden;
	text-overflow: ellipsis;
	display: box;
	word-wrap: break-word;
	-webkit-line-clamp: 3;
	-webkit-box-orient: vertical;
}

.product-tag {
	position: absolute;
	top: .5rem;
	left: .5rem;
	color: #fff;
	background: #252525;
	display: block;
	margin-bottom: 0;
	text-align: center;
	padding: .25rem 1rem;
	border-radius: 4px;
	font-size: .875rem;

	&__adults {
		background: $red;
		color: white;
	}

	&__children {
		background: $green;
		color: white;
	}
}

.product-discount {
	position: absolute;
	top: .5rem;
	right: .5rem;
	color: #fff;
	background: $theme-color-secondary;
	display: block;
	margin-bottom: 0;
	text-align: center;
	padding: .25rem 1rem;
	border-radius: 4px;
	font-size: .875rem;
}

.product-price-current {
	color: $theme-color-secondary;
	text-align: center;
	margin-bottom: 0;

	strong {
		color: $theme-color-primary;
		font-size: 1.5rem;
		font-weight: 700;
		display: block;
	}
}

.product-price-before {
	color: $theme-color-secondary;
	text-align: center;
	margin-bottom: 0;
	text-decoration: line-through;
	line-height: 1;
}

.product-card-price {
	padding: 0 1rem;
}

.product-discount-sale-tag {
	position: absolute;
	top: 0;
	right: 2.5rem;
	background: url("../../images/icon-sale-tag.svg") no-repeat center center;
	background-size: 100% 100%;
	color: #fff;
	font-weight: 700;
	font-size: 1.0625rem;
	padding: 19px 17px 28px;
}
