.content-section {
	position: relative;
	padding-top: 4rem;
	padding-bottom: 4rem;
}

.content-section-stripe {
	background-color: $theme-color-blue-light;
	position: absolute;
	top: 50%;
	left: 0;
	width: 100%;
	height: 50%;
	transform: translateY(-50%);
	z-index: 1;
}

.content-header {
	margin-bottom: 3rem;
	color: $theme-color-secondary;

	strong {
		color: $theme-color-primary;
	}
}

.content-heading {
	font-size: 1.875rem;
	line-height: 2.25rem;
}

.content-kicker {
	color: $theme-color-primary;
	text-transform: uppercase;
	font-family: $fontHeading;
	font-weight: 700;
	padding: .5rem 0;
	margin: 0;
}

.content-bg-grey {
	background-color: $theme-color-blue-light;
}
