#masthead {
	display: flex;
	flex-direction: column;
	align-items: center;
	min-height: 120px;
	color: #fff;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	transition: background .3s ease-in-out, transform .3s ease-in-out;
	z-index: 2000;
	background: transparent;

	@media all and (max-width: $breakpoint-menu) {
		height: 80px;

		&.active-menu {
			background: $theme-color-secondary;
		}
	}

	@media all and (max-width: 520px) {

		.user-menu-button {
			display: none;
		}
	}

	&.smaller {
		background: $theme-color-secondary;
	}

	&.hidden {
		transform: translateY(-100%);
	}
}

#masthead-divider {
	height: 120px;
	content: "";

	@media all and (max-width: $breakpoint-menu) {
		height: 80px;
	}
}

.header-wrapper {
	display: flex;
	flex-wrap: nowrap;
	align-items: center;
	justify-content: flex-end;
	padding: 2rem 0;

	@media all and (max-width: $breakpoint-menu) {
		padding: .875rem 0;
	}

	&.scrolled {
		height: 70px;
	}
}

.main-logo-link {
	display: flex;
	margin-right: auto;

	&:focus-visible {
		outline: 2px solid black;
		color: white;
		outline-offset: 10px;
	}

	@media all and (max-width: $breakpoint-menu) {

		img {
			width: 130px;
			height: auto;
		}
	}
}

.hamburger-button {
	height: 50px;
	position: relative;
	cursor: pointer;
	display: none;
	z-index: 1100;
	padding-left: 36px;

	span {
		font-weight: 700;
	}

	@media all and (max-width: $breakpoint-menu) {
		display: flex;
		align-items: center;
	}

	.hamburger-button-wrapper {
		position: absolute;
		top: 50%;
		left: 10px;
		transform: translateY(-50%);
		width: 16px;
		height: 12px;
	}

	.line {
		width: 16px;
		height: 2px;
		background-color: #fff;
		position: absolute;
		right: 0;
		transform: rotate(0deg);
		transition: all .3s ease-in-out;

		&.line-1 {
			top: 0;
		}

		&.line-2 {
			top: 5px;
		}

		&.line-3 {
			bottom: 0;
		}
	}

	&:hover {

		.line {

			&.line-1 {
				width: 50%;
			}

			&.line-3 {
				width: 75%;
			}
		}
	}

	&.active {

		.line {

			&.line-1 {
				width: 100%;
				transform: rotate(-135deg);
				top: 5px;
			}

			&.line-2 {
				width: 0;
				right: 50%;
			}

			&.line-3 {
				width: 100%;
				transform: rotate(135deg);
				bottom: 5px;
			}
		}
	}
}

.header-stripe {
	background: $theme-color-primary;
	width: 100%;
	padding: .5rem 0;
}
