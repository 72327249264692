.account-login {
	padding: 5rem 7.5rem;
	height: 100%;

	@media all and (max-width: 768px) {
		padding-left: 1rem;
		padding-right: 1rem;
	}

	.button-go-back {
		margin-bottom: 2.5rem;
	}
}

.account-login-form-wrapper {
	max-width: 365px;
	width: 100%;
}
