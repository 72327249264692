.hero {
	position: relative;
	width: 100%;
	background-color: #353535;
	background-position: center;
	background-size: cover;
	padding-top: 15rem;
	padding-bottom: 12.5rem;
	color: #fff;
	overflow: hidden;

	&:after {
		position: absolute;
		content: "";
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 1;
		background: linear-gradient(180deg, rgba(0, 0, 0, 25%) 0%, rgba(0, 0, 0, 70%) 85.42%);
	}

	&__page {
		height: 350px;
	}

	&__product {
		height: 700px;

		@media all and (max-width: 1280px) {
			height: 550px;
		}

		@media all and (max-width: 768px) {
			height: 240px;
		}
		//max-height: 600px;
		//padding-top: 0;
		//padding-bottom: 46.875%;

		&:after {
			background: linear-gradient(180deg, rgba(0, 0, 0, 50%) 0%, rgba(0, 0, 0, 0%) 100%);
		}
	}

	&__account {
		min-height: 300px;
		padding-top: 10rem;

		//&:after {
		//	background: linear-gradient(180deg, rgba(0, 0, 0, 50%) 0%, rgba(0, 0, 0, 0%) 100%);
		//}
	}

	.container {
		position: relative;
		z-index: 2;
	}

	@media all and (max-width: 768px) {
		padding-top: 11.25rem;
		padding-bottom: 5rem;
	}
}

.hero-bg {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 0;
	object-fit: cover;
	object-position: center;
}

.hero-title {
	font-size: 3.375rem;
	margin-bottom: 1.5rem;
	text-align: center;
	margin-left: auto;
	margin-right: auto;
	max-width: 740px;

	strong {
		color: $theme-color-primary;
	}

	//@media all and (max-width: 768px) {
	//	text-align: left;
	//}
}

.hero-subtitle {
	font-size: 1.0625rem;
	margin-bottom: 0;
	font-weight: 700;
	margin-left: auto;
	margin-right: auto;
	max-width: 640px;

	//@media all and (max-width: 768px) {
	//	text-align: left;
	//}
}

.hero-button {
	margin-top: 3rem;
}

.hero-account-code {
	font-size: 1.5rem;
	margin-bottom: 1em;
}

.hero-arrow {
	position: absolute;
	bottom: 0;
	left: 50%;
	transform: translate(-50%, 50%);
	z-index: 2;
}
