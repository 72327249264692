.button-go-back {
	position: relative;
	display: inline-flex;
	padding: .5rem 1rem .5rem 2.5rem;
	background-image: url("../../images/icon-go-back.svg");
	background-repeat: no-repeat;
	background-position: left 1rem center;
	background-size: .75em auto;
	color: $theme-color-secondary;
	font-weight: 700;
	text-decoration: none;
	transition: all .2s ease-in-out;
	border: 5px solid $theme-color-secondary;
	text-transform: lowercase;

	&:hover {
		border: 5px solid $theme-color-primary;
		background-position: left .5rem center;
		color: $theme-color-secondary;
	}
}

.button {
	position: relative;
	display: inline-block;
	color: $theme-color-primary;
	font-size: 1rem;
	font-weight: 700;
	text-decoration: none;
	line-height: 1.5;
	background-color: transparent;
	border: 3px solid $theme-color-primary;
	outline: none;
	padding: .6875rem 1.1875rem;
	transition: all .2s ease-in-out;
	overflow: hidden;
	text-align: center;
	white-space: nowrap;

	&__bigger {
		font-size: 1.0625rem;
		line-height: 1.47;
		padding: .9375rem 1.6875rem;
	}

	&.footer-app-button {
		padding: .6875rem 1.1875rem;
		font-size: 1rem;
		border: 5px solid $theme-color-primary;
		color: white;
		background: $theme-color-secondary;
	}

	&:hover {
		background-color: $theme-color-primary;
		color: #fff;
	}

	&.disabled,
	&:disabled {
		color: $theme-color-primary;
		background: white;
		border: 1px solid $theme-color-primary;
	}

	&__blue,
	&.blue {
		color: #fff;
		background-color: $theme-color-secondary;
		border: 5px solid $theme-color-secondary;

		&:hover {
			background-color: $theme-color-primary;
			color: #fff;
		}

		&.disabled,
		&:disabled {
			color: $theme-color-primary;
			background: white;
			border: 1px solid $theme-color-primary;
		}
	}

	&__white,
	&.white {
		color: #fff;
		background-color: transparent;
		border: 5px solid #fff;

		&:hover,
		&:active,
		&:focus {
			background-color: #fff;
			color: $theme-color-secondary;
		}
	}

	&__red,
	&.red {
		color: white;
		background-color: $theme-color-primary;
		border: 5px solid $theme-color-primary;

		&:hover {
			background-color: $theme-color-primary;
			color: #fff;
		}

		&.disabled,
		&:disabled {
			color: $theme-color-primary;
			background: white;
			border: 1px solid $theme-color-primary;
		}
	}

	&.icon-arrow-right {
		background-image: url("../../images/icon-arrow-right.svg");
		background-repeat: no-repeat;
		background-position: left 1.1875rem center;
		background-size: 20px auto;
		padding-left: 3.0625rem;

		&__bigger {
			padding-left: 3.5625rem;
		}

		&.button__white:hover,
		&.white:hover {
			background-image: url("../../images/icon-arrow-right-blue.svg");
		}
	}

	&.icon-arrow-down {
		background-image: url("../../images/button-icon-arrow-down-white.svg");
		background-repeat: no-repeat;
		background-position: left 1.1875rem center;
		background-size: 20px auto;
		padding-left: 3.0625rem;

		&__bigger {
			padding-left: 3.5625rem;
			background-position: left 1.6875rem center;
		}
	}

	&__blue-white {
		color: $theme-color-secondary;
		background-color: #fff;
		border: 5px solid $theme-color-secondary;

		&:hover {
			background-color: $theme-color-secondary;
			color: #fff;
		}

		&.icon-arrow-right {
			background-image: url("../../images/icon-arrow-right-red.svg");

			&:hover {
				background-image: url("../../images/icon-arrow-right.svg");
			}
		}

		&.icon-arrow-down {
			background-image: url("../../images/button-icon-arrow-down-red.svg");

			&:hover {
				background-image: url("../../images/button-icon-arrow-down-white.svg");
			}
		}
	}
}
