form.account-form {
	background-color: #fff;
	padding: 1rem;
	border-radius: 10px;

	fieldset {
		border: none;
		padding: 0;
		margin: 0;

		legend {
			font-size: 1.5rem;
			font-weight: 700;
			margin-bottom: 1rem;
			color: $theme-color-primary;
		}
	}
}
