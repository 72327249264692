.card {
	display: flex;
	flex-direction: column;
	width: 100%;
	max-width: 358px;
	margin: 0 auto;
	background-color: white;
	border-radius: 11px;
	box-shadow: 0 0 5px rgba(black, 10%);
}

.card-header {
	padding: 1rem;
}

.card-body {
	padding: 1rem 0;
	display: flex;
	flex-wrap: wrap;
}

.card-info-wrapper {
	padding: 0 1rem;
	line-height: 1.2;
	display: flex;
	flex-direction: column;
	margin-bottom: 1rem;
	width: 100%;

	&.w50 {
		width: 50%;
	}
}

.card-info-label {
	font-size: .875rem;
	font-weight: 700;
	text-transform: uppercase;
	color: $theme-color-primary;
}

.card-info-value {
	color: $theme-color-secondary;

	&.fullName {
		font-size: 1.75rem;
		font-weight: 300;
	}
}

.text-right {
	text-align: right;
}

.card-qrcode {
	padding: 1rem;
	margin: 0 auto 2rem;
	text-align: center;
}
