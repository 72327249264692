.scan {
	background: #fff;
	width: 100%;
	height: 100%;
	min-height: 100vh;
	padding-top: 1rem;
	padding-bottom: 1rem;

	&__error {
		background: #f00;
	}
}

.scan-content {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	max-width: 375px;
	width: 100%;
	margin: 0 auto;
}

.scan-title {
	font-size: 1.25rem;
	font-weight: 700;
	margin-bottom: .5rem;
	text-align: center;
}

.scan-status {
	position: relative;

	img {
		width: 100%;
		height: auto;
	}
}

.scan-status-text {
	color: $theme-color-secondary;
	font-size: 2rem;
	font-weight: 700;
	line-height: 1.2;
	text-align: center;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);

	span {
		color: $theme-color-primary;
	}
}

.scan-status-check {
	position: absolute;
	top: 20%;
	right: 11%;
	width: 75px;
	height: 75px;
	background-color: #3da435;
	border-radius: 50%;
	background-position: center;
	background-repeat: no-repeat;
	animation: heartbeat 1s infinite;

	&__success {
		background-image: url("../../images/scan-icon-success.svg");
	}

	&__error {
		background-color: $theme-color-primary;
		background-image: url("../../images/scan-icon-error.svg");
	}
}

// add animation like heartbeat
@keyframes heartbeat {

	0%,
	20%,
	50%,
	80%,
	100% {
		transform: scale(1);
	}

	40% {
		transform: scale(1.2);
	}

	60% {
		transform: scale(1.1);
	}
}
