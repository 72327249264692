$layout-width: 1040px;
$breakpoint-menu: 1024px;
$breakpoint-menu-logged: 768px;
$breakpoint-tablet: 768px;
$breakpoint-mobile: 620px;
$fontHeading: "MatterSQ";
$font-text: "MatterSQ";

$theme-color-text: #1d34fe;
$theme-color-heading: #1d34fe;
$theme-color-primary: #fb5271;
$theme-color-secondary: #1d34fe;
$theme-color-blue-light: #f4f5ff;
$theme-bg-primary-gradient: linear-gradient(to left bottom, #cb61d5, #96369f);

// Bootstrap
$enable-caret: true;
$enable-rounded: true;
$enable-shadows: false;
$enable-gradients: false;
$enable-transitions: true;
$enable-prefers-reduced-motion-media-query: false;
$enable-grid-classes: true;
$enable-pointer-cursor-for-buttons: true;
$enable-print-styles: false;
$enable-responsive-font-sizes: true;
$enable-validation-icons: true;
$enable-deprecation-messages: false;

$font-size-root: 16px;
$font-family-sans-serif: "Noto Sans", sans-serif, usual, system-ui, "Helvetica Neue", -apple-system, sans-serif,
	"Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
$headings-font-family: $font-family-sans-serif;

$headings-font-weight: 700;

$grid-columns: 12;

$gray-200: #eaeaea;
$blue: #2491ce;
$dark-blue: #003380;
$green: #4e9d2d;
$red: #c3002f;
$yellow: #ffb819;
$cyan: #4abbe8;

$primary: $blue; // <-- Zde nahoď hlavní barvu zákazníka

$zindex-modal: 512;
$zindex-modal-backdrop: $zindex-modal;
$modal-backdrop-bg: rgba(black, .5);
$modal-backdrop-opacity: 1;

// Select2
$s2bs-padding-base-vertical: .2rem;
$s2bs-padding-base-horizontal: 1rem;
$s2bs-padding-small-horizontal: .5rem;
$s2bs-font-size-base: 1rem;

// Loader
$spinner-size: 4rem;

// Badges
$badge-font-size: .9em;
$badge-font-weight: 400;
$badge-padding-y: .35em;
$badge-padding-x: .65em;
$badge-border-radius: 5px;
