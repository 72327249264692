@import "~select2";
//@import "~select2-bootstrap-5-theme/dist/select2-bootstrap-5-theme.css";

.items-filter-dropdown {
	position: relative;
	display: inline-block;
}

.items-filter-dropdown-content {
	display: block;
	position: relative;
	background-color: #fff;
	list-style: none;
	padding: 0;
	min-width: 280px;
	max-height: 0;
	overflow: hidden;
	visibility: hidden;
	margin: 0 0 30px;
	appearance: none;
	overflow-y: auto;

	&::-webkit-scrollbar {
		width: 12px;
	}

	&::-webkit-scrollbar-track {
		background: #e8ebff;
	}

	&::-webkit-scrollbar-thumb {
		background: $theme-color-secondary;
	}

	&.left {
		right: auto;
		left: 0;
	}

	&.open {
		visibility: visible;
		max-height: 22.75rem;
	}

	li {
		display: flex;
		align-items: center;
		position: relative;
		font-weight: 700;
		font-size: 1.125rem;
		margin: 0;
		padding: 1rem 2rem;
		white-space: nowrap;
		cursor: pointer;

		&.disabled {
			display: none;
		}

		&:focus-visible {
			outline: 0;
			box-shadow: 0 0 0 2px $theme-color-primary;
		}

		.checkbox {
			width: 1.125rem;
			height: 1.125rem;
			margin-right: 1rem;
			border-radius: 2px;
			border: 1px solid $theme-color-secondary;
		}

		.items-filter-count {
			align-self: flex-end;
			margin-left: auto;
			color: $theme-color-secondary;
			font-weight: 400;
		}

		svg {
			fill: $theme-color-secondary;
		}

		&.active {

			.checkbox {
				background: $theme-color-secondary url("../../images/icon-checked.svg") no-repeat center center;
			}

			svg {
				fill: $theme-color-primary;
			}
		}

		&.sorting-desc svg {
			transform: rotate(180deg);
		}
	}
}

.items-filter-dropdown-button {
	position: relative;
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	color: $theme-color-primary;
	font-size: 1.0625rem;
	font-weight: 700;
	text-decoration: none;
	line-height: 1.38em;
	background-color: #fff;
	border: 0;
	border-bottom: 1px solid $theme-color-secondary;
	outline: none;
	padding: 1.5rem 2rem;
	transition: all .2s ease-in-out;
	overflow: hidden;
	text-align: left;

	&:focus-visible {
		outline: 0;
		box-shadow: 0 0 0 2px $theme-color-primary;
	}

	&:after {
		display: block;
		content: "";
		width: 16px;
		height: 11px;
		background: url("../../images/icon-dropdown-blue.svg");
		margin-left: 1rem;
		transition: transform .1s ease-in-out;
	}

	&[aria-expanded="true"]:after {
		transform: rotate(180deg);
	}
}

.select2-container {
	width: 100%;
	margin-bottom: 30px;
}

//.select2-selection {
//	border: 0;
//	border-bottom: 1px solid $theme-color-secondary;
//	outline: none;
//	padding: 1.5rem 2rem;
//	transition: all .2s ease-in-out;
//	overflow: hidden;
//	text-align: left;
//	text-transform: lowercase;
//}
