#main-menu {
	display: flex;

	&:not(.loggedin) {
		@media all and (max-width: $breakpoint-menu) {
			display: none;
		}
	}
}

.main-menu-list {
	list-style: none;
	margin: 0;
	display: flex;
	gap: 2.5rem;
}

.main-menu-item {
	position: relative;
	display: flex;

	&.active {

		.main-menu-link {
			border-bottom: 4px solid $theme-color-primary;
		}
	}

	&:hover {

		ul.sub-menu {
			display: block;
		}
	}

	ul.sub-menu {
		display: none;
		position: absolute;
		background-color: #f9f9f9;
		padding: .5rem 0;
		box-shadow: 0 2px 5px rgba(0, 0, 0, 10%);
		list-style: none;
		margin: 0;
		min-width: 200px;
		left: 0;
		top: 100%;
		z-index: 100;

		li {
			display: flex;

			a {
				color: $theme-color-secondary;
				padding: .75rem 1.25rem;
				font-size: 1.125rem;
				font-weight: 700;
				text-decoration: none;
				width: 100%;

				&:hover {
					background-color: #f4f5ff;
				}

				&.active {
					color: $theme-color-primary;
				}
			}
		}
	}
}

.main-menu-link {
	text-decoration: none;
	color: #fff;
	font-weight: 700;
	font-size: 1rem;
	border-bottom: 4px solid transparent;
	line-height: 140%;
	transition: text-shadow .2s ease-in-out;
	padding: .5rem 0;

	&:hover,
	&:focus,
	&:focus-visible {
		color: #fff;
	}

	&:hover:not(.active) {
		text-shadow: 0 0 5px rgba(0, 0, 0, 50%);
	}

	&:focus-visible {
		outline: none;
		background-color: $theme-color-secondary;
		color: #fff;
		border-radius: 5px;
	}
}

.user-menu {
	display: flex;
	margin-left: 2.5rem;
}

.user-menu-button {
	position: relative;
	display: inline-block;
	color: #fff;
	font-size: 1rem;
	text-decoration: none;
	line-height: 1.38em;
	background-color: transparent;
	border: 5px solid $theme-color-primary;
	outline: none;
	padding: .6875rem 1.1875rem;
	transition: all .2s ease-in-out;
	overflow: hidden;
	text-align: center;
	font-weight: 700;

	&:hover {
		background-color: $theme-color-primary;
		color: #fff;
	}

	&.disabled,
	&:disabled {
		color: $theme-color-primary;
		background: white;
		border: 1px solid $theme-color-primary;
	}
}

#mobile-main-menu {
	background: $theme-color-secondary;
	border-top: 1px solid #fff;
	width: 100%;
	position: fixed;
	right: 0;
	top: 80px;
	transition: all .3s ease-in-out;
	max-height: 0;
	overflow: hidden;
	opacity: 0;
	padding: 2rem 0 3.5rem;
	z-index: -1;

	&.active {
		display: block;
		max-height: 1000px;
		opacity: 1;
		z-index: 1000;

		@media all and (min-width: $breakpoint-menu + 1) {
			display: none;
			opacity: 0;
			max-height: 0;
		}
	}
}

.mobile-menu-list {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	list-style: none;
	padding: 0;
	margin: 0;

	ul.sub-menu {
		background-color: #f9f9f9;
		padding: .5rem 0;
		box-shadow: 0 2px 5px rgba(0, 0, 0, 10%);
		list-style: none;
		margin: -1.5rem 0 0;
		left: 0;
		top: 100%;
		z-index: 100;

		li {
			display: flex;

			a {
				color: $theme-color-secondary;
				padding: .75rem 1.25rem;
				font-size: 1.125rem;
				font-weight: 700;
				text-align: center;
				text-decoration: none;
				width: 100%;

				&:hover {
					background-color: #f4f5ff;
				}

				&.active {
					color: $theme-color-primary;
				}
			}
		}
	}
}

.mobile-menu-link {
	margin-bottom: 1.5rem;
	font-weight: 700;
	line-height: 1.5;
	color: #fff;
	text-decoration: none;
	padding: .5rem 0;
	display: block;
	text-align: center;

	&.active {
		border-bottom: 4px solid $theme-color-primary;
	}
}
