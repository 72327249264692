.intro {
	position: relative;
	background-color: #353535;
	padding-top: 10rem;
	padding-bottom: 7.25rem;
	color: #fff;
	overflow: hidden;

	&:after {
		position: absolute;
		content: "";
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 1;
		background: linear-gradient(180deg, rgba(0, 0, 0, 25%) 0%, rgba(0, 0, 0, 70%) 85.42%);
	}

	@media all and (max-width: 620px) {
		padding-bottom: 5rem;
	}
}

.intro-bg {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 0;
	//transform: translateY(-50%);
	object-fit: cover;
	object-position: center;
}

.intro-wrapper {
	position: relative;
	gap: 64px;
	z-index: 2;
}

.promo-block {
	display: flex;
	width: 100%;
	max-width: 650px;
	flex-direction: column;
	font-size: 1rem;
	position: relative;
	z-index: 5;

	@media all and (max-width: 768px) {
		width: 100%;
	}
}

.promo-block-heading {
	font-size: 3.375rem;
	font-family: $fontHeading;
	font-weight: 700;
	line-height: 109.259%;
	color: #fff;
	margin-bottom: 1.25rem;

	@media all and (max-width: 620px) {
		font-size: 2.625rem;
		line-height: 114.256%;
	}
}

.promo-block-text {
	font-size: 1.125rem;
	width: 100%;
	margin-bottom: 2.5rem;
	font-weight: 700;
	line-height: 133.333%;

	@media all and (max-width: 1024px) {
		font-size: 1.125rem;
	}
}

.promo-features {
	font-family: $fontHeading;
	font-size: 1.0625rem;
	line-height: 147.059%;
	list-style: none;
	padding: 0;

	li {
		padding-left: 2em;
		background-image: url("../../images/icon-check.svg");
		background-repeat: no-repeat;
		background-position: left center;
		background-size: 24px 24px;

		& + li {
			margin-top: .75em;
		}
	}

	&.smaller {
		font-size: 1rem;
	}
}

.promo-block-image {

	img {
		width: 100%;
		height: auto;
	}

	@media all and (max-width: 768px) {
		display: none;
	}
}

.intro-button {
	@media all and (max-width: 768px) {
		text-align: center;
	}
}
