html,
body {
	width: 100%;
	height: 100%;
}

.page4xx {
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	overflow: hidden;
	position: relative;
	color: #fff;
	font-weight: 700;
	text-align: center;
	padding-top: 120px;

	@media (max-width: 768px) {
		padding-top: 80px;
		text-align: left;
	}

	&:after {
		position: absolute;
		content: "";
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 1;
		background: linear-gradient(180deg, rgba(0, 0, 0, 30%) 0%, rgba(0, 0, 0, 50%) 62.5%);
	}

	.container {
		position: relative;
		z-index: 2;
		max-width: 740px;
	}
}

.page4xx-bg {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 0;
	object-fit: cover;
	object-position: center;
}

.page4xx-heading {
	margin-bottom: 1.25rem;
	font-weight: 700;
	font-size: 3.375rem;

	strong {
		color: $theme-color-primary;
	}
}
