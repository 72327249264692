@import "fonts";
@import "variables";
//@import "~bootstrap/scss/functions";
//@import "~bootstrap/scss/variables";
//@import "~bootstrap/scss/mixins";
//@import "~bootstrap/scss/utilities";
@import "~glightbox/dist/css/glightbox.min.css";
@import "../../../node_modules/vanillajs-datepicker/dist/css/datepicker.css";
@import "layout/*.scss";
@import "components/*.scss";
@import "pages/*.scss";
@import "../../../node_modules/swiper/swiper";
@import "../../../node_modules/swiper/modules/navigation";
@import "~leaflet/dist/leaflet.css";
@import "~leaflet.markercluster/dist/MarkerCluster.css";
@import "~leaflet.markercluster/dist/MarkerCluster.Default.css";

body,
html {
	min-width: 340px;
	font-family: $font-text;
	color: $theme-color-text;
	position: relative;

	&.no-scroll {
		overflow-y: hidden;
	}
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-family: $fontHeading;
	font-weight: 700;
}

.loader {
	display: none;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(#fff, 65%);
	z-index: 9999;

	&:before {
		content: "";
		display: block;
		position: absolute;
		top: 50%;
		left: 50%;
		width: 60px;
		height: 60px;
		margin: -30px 0 0 -30px;
		border-radius: 50%;
		border: 5px solid $theme-color-primary;
		border-top-color: transparent;
		animation: spin 1s linear infinite;
	}
}

@keyframes spin {

	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}

body.is-loading {

	.loader {
		display: block;
	}
}

a,
.link {
	color: $theme-color-primary;

	&:hover {
		color: $theme-color-primary;
		text-decoration: none;
	}

	&.arrow {
		font-weight: 700;

		&:before {
			display: inline-block;
			content: "";
			background: url("../images/icon-link-arrow.svg") no-repeat left center;
			background-size: 18px auto;
			margin-right: 1rem;
			width: 18px;
			height: 12px;
			vertical-align: middle;
		}
	}
}

.color-primary {
	color: $theme-color-primary;
}

.hr-dotted {
	display: block;
	width: 100%;
	border: 0;
	border-top: 3px dashed $theme-color-secondary;
	opacity: 1;
	background-color: unset;
}

.swiper-button-next,
.swiper-button-prev {
	position: absolute;
	top: 50%;
	width: 39px;
	height: 74px;
	margin-top: -37px;
	z-index: 10;
	cursor: pointer;
	border: 0;
	outline: none;
	transition: all .3s ease-in-out;

	&:after {
		display: block;
		content: "";
		background: url("../images/slide-left-white.svg") no-repeat center center;
		background-size: 39px auto;
		width: 39px;
		height: 74px;
		opacity: 1;
		transition: all .3s ease-in-out;
	}

	&.swiper-button-disabled {
		opacity: .5;
		cursor: default;

		&:after {
			opacity: .5;
		}
	}

	&.swiper-button-prev {
		left: 10%;
	}

	&.swiper-button-next {
		right: 10%;

		&:after {
			transform: rotate(180deg);
		}
	}
}

.invalid-feedback {
	display: block;
	width: 100%;
	margin-top: .25rem;
	color: #dc3545;
	font-weight: 700;
}

.input-password-eye {
	content: "";
	display: block;
	position: absolute;
	bottom: .25rem;
	right: 0;
	width: 1.25rem;
	height: 1.25rem;
	background: url("../images/icon-eye.svg") no-repeat center center;
	background-size: 1.25rem auto;
	cursor: pointer;
	z-index: 2;
}
