#results-layout {
	display: flex;
	flex-flow: row wrap;
	margin-left: -15px;
	margin-right: -15px;

	@media all and (max-width: 768px) {

		.results-data-col {
			width: 50%;
		}
	}

	@media all and (max-width: 680px) {

		.results-data-col {
			width: 100%;
		}
	}

	&.filter-open {

		.results-filter {
			display: flex;
			flex: 1 0 33.333%;
		}

		.results-data {
			flex: 1 0 66.666%;
		}

		.results-data-col {
			width: 50%;
		}

		@media all and (max-width: 768px) {

			.results-filter {
				flex: 1 0 50%;
			}

			.results-data {
				flex: 1 0 50%;
			}

			.results-data-col {
				width: 100%;
			}
		}

		@media all and (max-width: 680px) {

			.results-filter {
				flex: 1 0 100%;
			}

			.results-data {
				flex: 1 0 100%;
			}
		}
	}
}

.results-filter,
.results-data {
	padding-left: 15px;
	padding-right: 15px;
}

.results-filter {
	display: none;
	flex-flow: column wrap;
}

.results-data {
	flex: 1 0 100%;
}

.results-data-wrapper {
	display: flex;
	flex-wrap: wrap;
	margin-left: -15px;
	margin-right: -15px;
}

.results-data-col {
	padding-left: 15px;
	padding-right: 15px;
	width: 33.3333%;
	margin-bottom: 30px;
}
