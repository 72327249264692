.container {
	max-width: $layout-width + 100;
	width: 100%;
	min-height: 1px;
	padding-left: 50px;
	padding-right: 50px;
	margin-left: auto;
	margin-right: auto;

	@media all and (max-width: 620px) {
		max-width: $layout-width + 32;
		padding-left: 16px;
		padding-right: 16px;
	}

	@media all and (orientation: landscape) and (max-width: 813px) {
		padding-left: 0;
		padding-right: 0;
		max-width: 90%;
	}
}

.container-fluid {
	width: 100%;
	min-height: 1px;
	padding-left: 50px;
	padding-right: 50px;
	margin-left: auto;
	margin-right: auto;
}
