#faq {
	padding: 4rem 0;
}

.faq-item {
	display: flex;
	flex-wrap: wrap;
	background: #f4f5ff;

	& + .faq-item {
		margin-top: 1rem;
	}
}

.faq-question {
	position: relative;
	flex: 1 1 100%;
	background: #f4f5ff;
	border: 0;
	outline: 0;
	text-align: left;
	display: inline-block;
	padding: 1rem 4rem 1rem 1.5rem;
	font-size: 1.125rem;
	color: $theme-color-secondary;
	font-weight: 700;
	line-height: 133.33%;

	&:before {
		position: absolute;
		content: "";
		right: 1.5rem;
		top: 50%;
		transform: translateY(-50%);
		display: inline-block;
		width: 1.25rem;
		height: 1.25rem;
		background: url("../../images/faq-icon-down.svg") no-repeat center center;
		background-size: contain;
		transition: all .1s ease-in-out;
	}

	&.open {

		&:before {
			transform: translateY(-50%) rotate(180deg);
		}
	}
}

.faq-answer {
	flex: 1 1 100%;
	max-height: 0;
	transition: all .2s ease-in-out;
	overflow: hidden;
	opacity: 0;
	padding: 0 1.5rem;
	z-index: -10;

	&.open {
		opacity: 1;
		z-index: 10;
		padding: 0 1.5rem 1rem;
	}

	p:last-child {
		margin-bottom: 0;
	}
}
