@font-face {
	font-family: MatterSQ;
	src: url("../fonts/MatterSQ-Light.otf") format("opentype");
	font-weight: 400;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: MatterSQ;
	src: url("../fonts/MatterSQ-LightItalic.otf") format("opentype");
	font-weight: 400;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: MatterSQ;
	src: url("../fonts/MatterSQ-Bold.otf") format("opentype");
	font-weight: 700;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: MatterSQ;
	src: url("../fonts/MatterSQ-BoldItalic.otf") format("opentype");
	font-weight: 700;
	font-style: italic;
	font-display: swap;
}
