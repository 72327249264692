.hp-steps {
	display: flex;
	flex-wrap: wrap;
	align-items: start;
	gap: 2rem;

	.hp-steps-item {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		text-decoration: none;
		position: relative;
		flex: 1 1 calc(25% - 1.5rem);

		@media all and (max-width: $breakpoint-tablet) {
			flex: 1 1 calc(50% - 1rem);
			margin-bottom: 1.185rem;

			//	&:nth-child(3) {
			//		margin-bottom: 4rem;
			//	}
			//
			//	&.mobile-last {
			//		order: 3;
			//
			//		.hp-steps-icon:before {
			//			content: "";
			//			display: block;
			//			position: absolute;
			//			top: 0;
			//			left: 50%;
			//			width: 28px;
			//			height: 28px;
			//			background-image: url("../../images/icon-step-arrow-down-mobile.svg");
			//			background-size: auto 28px;
			//			background-repeat: no-repeat;
			//			background-position: center;
			//			transform: translate(-50%, -2.875rem);
			//		}
			//	}
		}
	}

	.hp-steps-icon {
		display: block;
		position: relative;
		width: 7rem;
		height: 7rem;
		border: 6px solid $theme-color-primary;
		background-position: center;
		background-size: 4rem auto;
		background-repeat: no-repeat;
		transition: all .2s ease-in-out;
		margin-bottom: 1.125rem;

		span {
			font-size: 3rem;
			font-weight: 700;
			color: $theme-color-secondary;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
		}

		&__1 {
			background-image: url("../../images/icon-step-1.svg");
		}

		&__2 {
			background-image: url("../../images/icon-step-2.svg");
		}

		&__3 {
			background-image: url("../../images/icon-step-3.svg");
		}

		&__4 {
			background-image: url("../../images/icon-step-4.svg");
		}
	}

	.hp-steps-item-title {
		color: $theme-color-secondary;
		font-size: 1.375rem;
		font-weight: 700;
		line-height: 1.18;
		text-align: center;
		transition: all .2s ease-in-out;
		margin-bottom: .75rem;

		@media all and (max-width: $breakpoint-tablet) {
			line-height: 1;
			margin-bottom: 0;
		}
	}

	.hp-steps-item-text {
		color: $theme-color-secondary;
		font-size: .875rem;
		line-height: 1.28;
		text-align: center;
		transition: all .2s ease-in-out;

		//@media all and (max-width: $breakpoint-tablet) {
		//	display: none;
		//}
	}

	//.hp-steps-arrow {
	//	flex: 0 0 1.875rem;
	//	height: 7rem;
	//	background-image: url("../../images/icon-step-arrow-right.svg");
	//	background-repeat: no-repeat;
	//	background-position: center;
	//	background-size: 30px auto;
	//
	//	&.mobile-hidden {
	//		display: none;
	//	}
	//
	//	@media all and (max-width: $breakpoint-tablet) {
	//		background-image: url("../../images/icon-step-arrow-right-mobile.svg");
	//		background-size: 28px auto;
	//
	//		&:nth-child(6) {
	//			transform: rotate(180deg);
	//			order: 2;
	//		}
	//	}
	//}
}
