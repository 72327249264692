.form {

	input[type="text"],
	input[type="number"],
	input[type="password"],
	input[type="url"],
	input[type="email"] {
		width: 100%;
		border: 0;
		border-bottom: 1px solid $theme-color-text;
		padding-top: .5rem;

		&:focus {
			outline: 0;
			border-bottom: 1px solid $theme-color-primary;
		}
	}

	select {
		appearance: none;
		background-image: url("../../images/icon-arrow-down.svg");
		background-repeat: no-repeat;
		background-position: right 1rem center;
		background-size: 12px 12px;
		width: 100%;
		border: 0;
		border-bottom: 1px solid $theme-color-text;
		padding-top: .5rem;

		&:focus {
			outline: 0;
			border-bottom: 1px solid $theme-color-primary;
		}
	}

	label {
		color: $theme-color-text;
	}

	.form-check {
		position: relative;

		input {
			width: 0;
			height: 0;
			position: absolute;
			left: 0;
			top: 0;
			opacity: 0;

			&:checked ~ .form-check-label:before {
				background-image: url("../../images/checkbox-checked.svg");
				background-position: center;
				background-repeat: no-repeat;
			}
		}

		.form-check-label {
			display: flex;
			align-items: center;
			width: 100%;

			&:before {
				display: inline-block;
				content: "";
				width: 1rem;
				height: 1rem;
				border: 2px solid $theme-color-text;
				margin-right: .675rem;
				transition: all .2s ease-in-out;
				background-image: none;
				background-position: center;
				background-repeat: no-repeat;
			}
		}
	}

	.code-input {
		background: $theme-color-blue-light;
		border-bottom: 0;
		padding-left: .5rem;
		padding-right: .5rem;
		padding-bottom: .25rem;
		text-transform: uppercase;
	}
}

.code-login-form {

	.code-divider {
		font-size: 2.5rem;
		margin: 0 .5rem;
	}

	input[type="text"] {
		width: 100%;
		border: 0;
		padding: .5rem;
		background: $theme-color-blue-light;
		border-radius: 5px;
		font-size: 2rem;
		font-weight: 700;
		text-transform: uppercase;
		text-align: center;

		&:focus {
			outline: 2px solid $theme-color-primary;
		}
	}
}

.form-custom-radio {
	display: flex;
	width: 100%;
	flex-wrap: wrap;
	margin-bottom: 2.5rem;
	gap: .5rem;

	@media all and (max-width: 1400px) {

		label {
			width: 100%;
		}
	}

	span {
		width: 100%;
		cursor: pointer;
	}

	input {
		display: none;

		&:checked + span {
			background-color: $theme-color-secondary;
			color: #fff;
		}
	}
}
